import {fetchWrapper} from '../_helpers/wrapper.net'

const checkCode = (code) => {
    return fetchWrapper.get(`${process.env.REACT_APP_SERVER_URL}/current-games/checkCode/${code}/studio`)
}

const createGame = () => {
    return fetchWrapper.post(`${process.env.REACT_APP_SERVER_URL}/current-games`, {host:"studio", gameID:"5fbe41525ac551ad4c654dc7"})
}


export const netService = { checkCode,createGame }