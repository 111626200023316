import React, { Suspense } from 'react'
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import PlayerLoader from '~loaders/PlayerLoader'
import player1, { player1_standing } from '~models/player1'
import player2, { player2_standing, player2_defeated, player2_victory } from '~models/player2'
import Background from './Background'
import face from '~models/male.glb'
import female from '~models/female.glb'

const PlayersView = () => {
    const loader = new FBXLoader()
    return (
        <>
            <Suspense fallback="Trwa ładowane">
                <PlayerLoader
                    file={player1}
                    id="1"
                    expected_height={.6}
                    position={{ x: 0, y: 0, z: 0 }}
                    lookAt={{ x: 0.4, y:0, z:-0.35}}
                    loader={loader}
                    animations={{ standing: player1_standing }}
                    face={female}
                />
            </Suspense>

            <Suspense fallback="Trwa ładowane">
                <PlayerLoader
                    file={player2}
                    id="2"
                    expected_height={.6}
                    position={{ x: 0.3, y: 0, z: 0.3 }}
                    lookAt={{ x: 0.4, y:0, z:-0.35 }}
                    loader={loader}
                    animations={{ standing: player2_standing, victory:player2_victory, defeated:player2_defeated }}
                    face={face}
                />
            </Suspense>

            <Suspense fallback="Trwa ładowane">
                <Background/>
            </Suspense>
        </>
    )
}

export default React.memo(PlayersView)