import React, { useEffect, useState } from 'react'
import AnimationToggler from '../CustomComponents/AnimationToggler';
import Loading from '../App/Loading'
import LayerToggler from '../CustomComponents/LayerToggler';
import { connect } from 'react-redux';
import { Canvas } from '@react-three/fiber'
import Scene from './Scene';
import CameraToggler from '../CustomComponents/CameraToggler';
import { ReactReduxContext, Provider } from 'react-redux';
import { netService, alertService } from '~services'
import {passSocket} from '~helpers/io'

const socket = passSocket()

const Wrapper = ({ loaded, followMouse, match, history }) => {
    const [codeChecked, toggleCode] = useState(false)
    useEffect(() => {
        const handleCheckCode = () => {
            netService.checkCode(match.params.id)
                .then(() => {
                    toggleCode(true)
                    socket.emit("studio_connected", {code:match.params.id})
                })
                .catch(err => {
                    toggleCode(true)
                    history.push(`/`)
                    alertService.error(err)
                })
        }
        
        handleCheckCode()
    }, [])

    return (
        <>
            {codeChecked ?
                <>
                    {!loaded && <Loading title="Trwa ładowanie sceny..." />}
                    <div style={{ position: "absolute", zIndex: 2, width: "100%", bottom: 0 }}>
                        <CameraToggler />
                        <br />
                        <AnimationToggler />
                        <br />
                        <LayerToggler />
                    </div>
                    <ReactReduxContext.Consumer>
                        {({ store }) => (
                            <Canvas>
                                <Provider store={store}>
                                    <Scene followMouse={followMouse} />
                                </Provider>
                            </Canvas>
                        )}
                    </ReactReduxContext.Consumer>
                </>
                :
                <Loading title="Trwa sprawdzanie kodu..." />
            }

        </>
    )
}

const mapStateToProps = (props) => {
    return { followMouse: props.followMouse, loaded: props.loader.counter === 4 && props.loader.layersLoaded }
}

export default connect(mapStateToProps)(Wrapper)