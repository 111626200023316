import { useThree } from '@react-three/fiber'
import { Vector3, Raycaster } from 'three'

export const useIntersect = () => {
    const { scene, camera } = useThree()
    const raycaster = new Raycaster();



    const turnOnRaycaster = () => {

        const handleMouseDown = (e) => {
            const mouse3D = new Vector3(
                (e.clientX / window.innerWidth) * 2 - 1,
                - (e.clientY / window.innerHeight) * 2 + 1,
                0.5
            );

            raycaster.setFromCamera(mouse3D, camera);
            if (scene.getObjectByName("studio")) {
                const intersects = raycaster.intersectObject(scene.getObjectByName("studio"), true)
            }
        }


        document.removeEventListener("mousedown", handleMouseDown, false)
        document.addEventListener("mousedown", handleMouseDown, false)

    }

    return [turnOnRaycaster];

}

